.timer--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: #fff;
}
.timer--counter {
  display: block;
  width: 2.55em;
  height: 1em;
  margin: 5px auto;
  font-size: 100px;
  font-weight: 500;
  line-height: 1;
  text-align: left;
}
.timer--input {
  outline: none;
  appearance: none;
  display: block;
  min-width: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: inherit;
  text-align: left;
  background: none;
}
.timer--input::-webkit-calendar-picker-indicator {
  display: none;
}
.timer--display {
  letter-spacing: 0.006em;
}
.timer--buttons {
  display: flex;
  position: relative;
}
.timer--start {
  outline: none;
  appearance: none;
  display: block;
  margin-top: 30px;
  padding: 0.2em 0.8em;
  border: 0;
  border-radius: 8em;
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  text-transform: uppercase;
  background: #150f69;
}

.timer--clock {
  display: block;
  position: relative;
  width: 1em;
  height: 1em;
  margin: 15px auto 0;
  border: 0.06em solid #fff;
  border-radius: 100%;
  font-size: 200px;
}
.timer--clock::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 1em;
  height: 1em;
  font-size: 28px;
  border-radius: 100%;
  transform: translateX(-50%);
  background: #fff;
}
.timer--clock-pointer {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  margin-top: -1px;
  margin-left: -1px;
}
.timer--clock-pointer circle {
  transition: stroke-dasharray calc(var(--interval, 1000) * 1ms) linear;
}

.timer--warning {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: max-content;
  max-width: calc(100vw - 50px);
  font-size: 42px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  transform: translateX(-50%);
}

@media screen and (max-width: 480px) {
  .timer--warning {
    font-size: 34px;
  }
}